<template>
  <div class="content_body" v-loading="loading">
    <div class="nav_header" style="padding: 0px">
      <el-form :inline="true" size="small" :model="searchSalePerformanceCommissionDetailData" @submit.native.prevent>
        <el-form-item v-if="storeEntityList.length > 1" label="开单门店">
          <el-select v-model="searchSalePerformanceCommissionDetailData.EntityID" clearable filterable placeholder="请选择门店" :default-first-option="true" @change="handleSalePerformanceCommissionSearch">
            <el-option v-for="item in storeEntityList" :key="item.ID" :label="item.EntityName" :value="item.ID"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间筛选">
          <el-date-picker v-model="searchSalePerformanceCommissionDetailData.QueryDate" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSalePerformanceCommissionSearchToo"></el-date-picker>
        </el-form-item>
        <el-form-item label="商品类型">
          <el-select v-model="searchSalePerformanceCommissionDetailData.GoodsTypeName" clearable filterable placeholder="请选择商品类型" :default-first-option="true" @change="handleSalePerformanceCommissionSearch">
            <el-option label="项目" value="项目"></el-option>
            <el-option label="储值卡" value="储值卡"></el-option>
            <el-option label="时效卡" value="时效卡"></el-option>
            <el-option label="通用次卡" value="通用次卡"></el-option>
            <el-option label="产品" value="产品"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="员工姓名">
          <el-input v-model="searchSalePerformanceCommissionDetailData.EmployeeName" clearable @keyup.enter.native="handleSalePerformanceCommissionSearch" @clear="handleSalePerformanceCommissionSearch" placeholder="请输入员工姓名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-prevent-click @click="handleSalePerformanceCommissionSearch">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button v-if="salePerformanceCommissionDetailExport" type="primary" size="small" :loading="downloadLoading" @click="downloadExcel">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table size="small" show-summary :summary-method="getSalePerformanceCommissionDetailListSummaries" :data="salePerformanceCommissionDetailList" v-loading="salePerformanceCommissionDetailLoading">
      <el-table-column prop="SaleBillID" label="订单编号"></el-table-column>
      <el-table-column prop="BillDate" label="下单日期"></el-table-column>
      <el-table-column prop="EntityName" label="下单门店"></el-table-column>
      <el-table-column prop="EmployeeName" label="员工"></el-table-column>
      <el-table-column prop="EmployeeName" label="会员/编号">
        <template slot-scope="scope">
          <span
            >{{ scope.row.CustomerName }}
            <span v-if="scope.row.CustomerCode">({{ scope.row.CustomerCode }})</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="GoodsTypeName" label="商品类型"></el-table-column>
      <el-table-column prop="CategoryName" label="商品类别"></el-table-column>
      <el-table-column prop="GoodName" label="商品名"></el-table-column>
      <el-table-column prop="BuyProperty" label="购买属性"></el-table-column>
      <el-table-column align="right" prop="PayPerformance" label="现金业绩">
        <template align="right" slot-scope="scope">
          <div v-if="scope.row.PayPerformance < 0" class="color_red">
            {{ scope.row.PayPerformance | NumFormat }}
          </div>
          <div v-else-if="scope.row.PayPerformance > 0" class="color_green">+{{ scope.row.PayPerformance | NumFormat }}</div>
          <div v-else>0.00</div>
        </template>
      </el-table-column>
      <el-table-column align="right" prop="PayCommission" label="现金提成">
        <template align="right" slot-scope="scope">
          <div v-if="scope.row.PayCommission < 0" class="color_red">
            {{ scope.row.PayCommission | NumFormat }}
          </div>
          <div v-else-if="scope.row.PayCommission > 0" class="color_green">+{{ scope.row.PayCommission | NumFormat }}</div>
          <div v-else>0.00</div>
        </template>
      </el-table-column>
      <el-table-column align="right" prop="SavingCardPerformance" label="卡扣业绩">
        <template align="right" slot-scope="scope">
          <div v-if="scope.row.SavingCardPerformance < 0" class="color_red">
            {{ scope.row.SavingCardPerformance | NumFormat }}
          </div>
          <div v-else-if="scope.row.SavingCardPerformance > 0" class="color_green">+{{ scope.row.SavingCardPerformance | NumFormat }}</div>
          <div v-else>0.00</div>
        </template>
      </el-table-column>
      <el-table-column align="right" prop="SavingCardCommission" label="卡扣提成">
        <template align="right" slot-scope="scope">
          <div v-if="scope.row.SavingCardCommission < 0" class="color_red">
            {{ scope.row.SavingCardCommission | NumFormat }}
          </div>
          <div v-else-if="scope.row.SavingCardCommission > 0" class="color_green">+{{ scope.row.SavingCardCommission | NumFormat }}</div>
          <div v-else>0.00</div>
        </template>
      </el-table-column>
      <el-table-column align="right" prop="SavingCardLargessPerformance" label="赠卡扣业绩">
        <template align="right" slot-scope="scope">
          <div v-if="scope.row.SavingCardLargessPerformance < 0" class="color_red">
            {{ scope.row.SavingCardLargessPerformance | NumFormat }}
          </div>
          <div v-else-if="scope.row.SavingCardLargessPerformance > 0" class="color_green">+{{ scope.row.SavingCardLargessPerformance | NumFormat }}</div>
          <div v-else>0.00</div>
        </template>
      </el-table-column>
      <el-table-column align="right" prop="SavingCardLargessCardCommission" label="赠卡扣提成">
        <template align="right" slot-scope="scope">
          <div v-if="scope.row.SavingCardLargessCardCommission < 0" class="color_red">
            {{ scope.row.SavingCardLargessCardCommission | NumFormat }}
          </div>
          <div v-else-if="scope.row.SavingCardLargessCardCommission > 0" class="color_green">+{{ scope.row.SavingCardLargessCardCommission | NumFormat }}</div>
          <div v-else>0.00</div>
        </template>
      </el-table-column>
      <el-table-column align="right" prop="SpecialBenefitCommission" label="特殊补贴">
        <template align="right" slot-scope="scope">
          <div v-if="scope.row.SpecialBenefitCommission < 0" class="color_red">
            {{ scope.row.SpecialBenefitCommission | NumFormat }}
          </div>
          <div v-else-if="scope.row.SpecialBenefitCommission > 0" class="color_green">+{{ scope.row.SpecialBenefitCommission | NumFormat }}</div>
          <div v-else>0.00</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pad_15 text_right">
      <el-pagination
        background
        v-if="salePerformanceCommissionDetailPaginations.total > 0"
        @current-change="handleSalePerformanceCommissionPageChange"
        :current-page.sync="salePerformanceCommissionDetailPaginations.page"
        :page-size="salePerformanceCommissionDetailPaginations.page_size"
        :layout="salePerformanceCommissionDetailPaginations.layout"
        :total="salePerformanceCommissionDetailPaginations.total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import EntityAPI from "@/api/Report/Common/entity";
import API from "@/api/Report/Employee/salePerformanceCommissionDetailStatement";
import permission from "@/components/js/permission.js";

export default {
  name: "EmployeeSalePerformanceCommissionDetail",

  beforeRouteEnter(to, from, next) {
    console.log(to);
    console.log(from);
    console.log(next);
    next((vm) => {
      vm.salePerformanceCommissionDetailExport = permission.permission(to.meta.Permission, "Report-Employee-SalePerformanceCommissionDetail-Export");
    });
  },
  data() {
    return {
      loading: false,
      downloadLoading: false,
      storeEntityList: [], //门店列表
      searchSalePerformanceCommissionDetailData: {
        EntityID: null,
        QueryDate: [new Date(), new Date()],
        GoodsTypeName: "",
        EmployeeName: "",
      },
      salePerformanceCommissionDetailList: [],
      salePerformanceCommissionDetailSum: {},
      salePerformanceCommissionDetailLoading: false,
      //需要给分页组件传的信息
      salePerformanceCommissionDetailPaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      salePerformanceCommissionDetailExport: false,
    };
  },
  methods: {
    //获得当前用户下的权限门店
    getstoreEntityList() {
      var that = this;
      that.loading = true;
      EntityAPI.getStoreEntityList()
        .then((res) => {
          if (res.StateCode == 200) {
            that.storeEntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },

    handleSalePerformanceCommissionSearch() {
      var that = this;
      that.salePerformanceCommissionDetailPaginations.page = 1;
      that.salePerformanceCommissionDetail();
    },
    handleSalePerformanceCommissionSearchToo(e) {
      var that = this;
      that.salePerformanceCommissionDetailPaginations.page = 1;
      that.searchSalePerformanceCommissionDetailData.QueryDate[(e[0], e[1])];
      that.salePerformanceCommissionDetail();
    },
    handleSalePerformanceCommissionPageChange(page) {
      this.salePerformanceCommissionDetailPaginations.page = page;
      this.salePerformanceCommissionDetail();
    },
    // 销售搜索
    salePerformanceCommissionDetail() {
      var that = this;
      if (that.searchSalePerformanceCommissionDetailData.QueryDate != null) {
        var params = {
          EntityID: that.searchSalePerformanceCommissionDetailData.EntityID,
          StartDate: that.searchSalePerformanceCommissionDetailData.QueryDate[0],
          //   StartDate: "2020-03-02",
          EndDate: that.searchSalePerformanceCommissionDetailData.QueryDate[1],
          GoodsTypeName: that.searchSalePerformanceCommissionDetailData.GoodsTypeName.trim(),
          EmployeeName: that.searchSalePerformanceCommissionDetailData.EmployeeName.trim(),
          PageNum: that.salePerformanceCommissionDetailPaginations.page,
        };
        that.salePerformanceCommissionDetailLoading = true;
        API.getEmployeeSalePerformanceCommissionList(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.salePerformanceCommissionDetailSum = res.Data.employeeSalePerformanceCommissionSumStatementForm;
              that.salePerformanceCommissionDetailList = res.Data.employeeSalePerformanceCommissionDetailStatementForms.List;
              that.salePerformanceCommissionDetailPaginations.total = res.Data.employeeSalePerformanceCommissionDetailStatementForms.Total;
              that.salePerformanceCommissionDetailPaginations.page_size = res.Data.employeeSalePerformanceCommissionDetailStatementForms.PageSize;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function() {
            that.salePerformanceCommissionDetailLoading = false;
          });
      }
    },

    getSalePerformanceCommissionDetailListSummaries() {
      const sums = [];
      sums[0] = <span class="font_weight_600">合计</span>;
      sums[1] = "";
      sums[2] = "";
      sums[3] = "";
      sums[4] = "";
      sums[5] = "";
      sums[6] = "";
      sums[7] = "";
      if (!this.salePerformanceCommissionDetailSum) {
        sums[8] = "";
        sums[9] = "";
        sums[10] = "";
        sums[11] = "";
        sums[12] = "";
        sums[13] = "";
        sums[14] = "";
        return sums;
      }
      var filter_NumFormat = this.$options.filters["NumFormat"];
      sums[9] = this.salePerformanceCommissionDetailSum ? <span class="font_weight_600">{filter_NumFormat(this.salePerformanceCommissionDetailSum.PayPerformance)}</span> : "";
      sums[10] = <span class="font_weight_600">{filter_NumFormat(this.salePerformanceCommissionDetailSum.PayCommission)}</span>;
      sums[11] = <span class="font_weight_600">{filter_NumFormat(this.salePerformanceCommissionDetailSum.SavingCardPerformance)}</span>;
      sums[12] = <span class="font_weight_600">{filter_NumFormat(this.salePerformanceCommissionDetailSum.SavingCardCommission)}</span>;
      sums[13] = <span class="font_weight_600">{filter_NumFormat(this.salePerformanceCommissionDetailSum.SavingCardLargessPerformance)}</span>;
      sums[14] = <span class="font_weight_600">{filter_NumFormat(this.salePerformanceCommissionDetailSum.SavingCardLargessCardCommission)}</span>;
      sums[15] = <span class="font_weight_600">{filter_NumFormat(this.salePerformanceCommissionDetailSum.SpecialBenefitCommission)}</span>;
      return sums;
    },

    /** 数据导出 */
    downloadExcel() {
      var that = this;
      let params = {
        EntityID: that.searchSalePerformanceCommissionDetailData.EntityID,
        StartDate: that.searchSalePerformanceCommissionDetailData.QueryDate[0],
        EndDate: that.searchSalePerformanceCommissionDetailData.QueryDate[1],
        GoodsTypeName: that.searchSalePerformanceCommissionDetailData.GoodsTypeName.trim(),
        EmployeeName: that.searchSalePerformanceCommissionDetailData.EmployeeName.trim(),
      };
      that.downloadLoading = true;
      API.exportEmployeeSalePerformanceCommissionDetailStatement(params)
        .then((res) => {
          this.$message.success({
            message: "正在导出",
            duration: "4000",
          });
          const link = document.createElement("a");
          let blob = new Blob([res], { type: "application/octet-stream" });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.download = "销售业绩提成明细.xlsx"; //下载的文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(function() {
          that.downloadLoading = false;
        });
    },
  },
  mounted() {
    var that = this;

    that.salePerformanceCommissionDetailExport = permission.permission(that.$route.meta.Permission, "Report-Employee-SalePerformanceCommissionDetail-Export");
    that.getstoreEntityList();
    that.handleSalePerformanceCommissionSearch();
  },
};
</script>
